<template>
  <div></div>
</template>

<script>
import * as filters from '@/apps/common/filters';
import _ from '@/apps/common/lodash';
import echarts from 'echarts';

const base = {
  animation: true,
  tooltip: {
    show: true,
    trigger: 'axis'
  },
  color: [],
  xAxis: {
    show: false,
    padding: 0,
    axisLabel: {
      show: false,
      interval: 0,
      overflow: false,
      color: '#fff',
      formatter: function (value) {
        let date = new Date(value);
        return filters.formatTime(date);
      }
    },
    type: 'time',
    data: [],
    axisPointer: {
      value: Date.now(),
      snap: true,
      lineStyle: {
        color: '#fff',
        opacity: 0.5,
        width: 2
      },
      label: {
        show: false
      },
      handle: {
        show: false,
        color: '#fff'
      }
    }
  },
  yAxis: {
    show: false,
    padding: 0,
    max: function (value) {
      return value.max < 20 ? 20 : value.max + 10;
    },
    type: 'value',
    axisLabel: {
      color: '#fff'
    }
  },
  dataZoom: [
    {
      start: 50,
      end: 100,
      type: 'slider',
      top: '90%',
      dataBackground: {
        lineStyle: {
          color: 'rgba(255,255,255,1)',
          opacity: 0.5
        }
      }
    },
    {
      start: 50,
      end: 100,
      type: 'inside',
      top: '90%'
    }
  ],
  title: {
    show: false
  },
  grid: {
    show: false,
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    containLabel: false
  },
  series: []
};

const seria = {
  type: 'line',
  data: [],
  animation: true,
  cursor: 'pointer',
  showSymbol: false,
  hoverAnimation: true,
  sampling: 'max',
  smoothMonotone: 'x',
  symbolSize: 6
};

export default {
  name: 'chart-area',
  props: {
    type: String,
    label: String,
    colors: Array,
    names: Array,
    values: {
      type: Array,
      required: true
    },
    clickHandler: Function,
    disposeBeforeUpdate: {
      type: Boolean,
      default: false
    },
    smooth: {
      type: Boolean,
      default: true
    },
    grid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      _echart: null,
      dataIndex: -1
    };
  },
  mounted() {
    window.addEventListener('resize', this.resize);
    this._echart = echarts.init(this.$el);
    this.updateOptions();
    this.initListeners();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
    this._echart.dispose();
    this._echart = null;
  },
  watch: {
    values() {
      this.updateOptions();
      this.resize();
    }
  },
  computed: {
    options() {
      let r = _.cloneDeep(base),
        faces = this.values.map(({ created_date, faces_count }) => [new Date(created_date), faces_count]),
        silhouettes = this.values.map(({ created_date, silhouettes_count }) => [new Date(created_date), silhouettes_count]),
        lastTime = faces.length && faces[faces.length - 1][0];
      lastTime && (r.xAxis.axisPointer.value = lastTime);
      const silhouettesGradient = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: this.colors[0][0] },
        { offset: 1, color: this.colors[0][1] }
      ]);
      const facesGradient = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: this.colors[1][0] },
        { offset: 1, color: this.colors[1][1] }
      ]);
      const opacity = 0.6;
      r.series = [
        Object.assign(_.cloneDeep(seria), {
          name: this.$tf('silhouettes'),
          data: silhouettes,
          smooth: this.smooth,
          areaStyle: { color: silhouettesGradient, opacity },
          itemStyle: { color: silhouettesGradient },
          lineStyle: { color: silhouettesGradient },
          zlevel: 1
        }),
        Object.assign(_.cloneDeep(seria), {
          name: this.$tf('faces'),
          data: faces,
          smooth: this.smooth,
          areaStyle: { color: facesGradient, opacity },
          itemStyle: { color: facesGradient },
          lineStyle: { color: facesGradient },
          zlevel: 2
        })
      ];

      if (this.grid) {
        r.grid.top = 10;
        r.grid.bottom = '10%';
        r.grid.right = 10;
      } else {
        r.dataZoom = null;
      }

      r.yAxis.show = r.xAxis.show = r.xAxis.axisLabel.show = r.grid.show = r.grid.containLabel = !!this.grid;
      return r;
    }
  },
  methods: {
    resize() {
      if (this._echart) this._echart.resize();
    },
    initListeners() {
      this._echart.on('highlight', (e) => (this.dataIndex = e.batch[0].dataIndex));
      this._echart.on('click', () => null);
      this._echart.getZr().on('click', (v) => {
        if (this.clickHandler) this.clickHandler(this.values[this.dataIndex], this.dataIndex, v);
      });
    },
    updateOptions() {
      if (this.disposeBeforeUpdate) {
        if (this._echart) this._echart.dispose();
        this._echart = echarts.init(this.$el);
        this.initListeners();
      }
      this._echart.setOption(this.options);
    }
  }
};
</script>
